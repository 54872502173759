import React, { useState, useEffect } from 'react';

export default () => {
  const [trackingIgnored, setTrackingIgnored] = useState();
  useEffect(() => {
    setTrackingIgnored(localStorage.plausible_ignore);
  }, []);

  return (
    <div>
      <p>tracking: {trackingIgnored ? 'no' : 'yes'}</p>
      <button
        onClick={() => {
          // eslint-disable-next-line camelcase
          localStorage.plausible_ignore = true;
          setTrackingIgnored(true);
        }}
      >
        Stop tracking
      </button>
    </div>
  );
};
